import { Calendar, Leader, RootState, ThunkDispatchType, actions } from "../../store";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ManageCalendars from "./ManageCalendars";
import { EVENT_TYPE, PAGE_URL } from "../../constants";
import { AddEditCalendarModal } from "../../components/ManageCalendars/AddEditCalendarModal";
import { trackEvent } from "../../utils/appAnalyticsUtils";
import { checkForCalendarGrants, checkForMicrosoftGrant } from "../../utils/authUtils";
import { useNavigate } from "react-router-dom";


export interface AssociationsDict {
  [leaderId: string]: number[];
}


const ManageCalendarsContainer = (): ReactElement => {

  const leaders = useSelector((state: RootState) =>  state.leaders);
  const user = useSelector((state: RootState) =>  state.auth.user);
  const calendars = useSelector((state: RootState) =>  state.schedule.calendars);

  const navigate = useNavigate();
  const [associations, setAssociations] = useState<AssociationsDict>({});
  const [additionalCalendars, setAdditionalCalendars] = useState<{ [eml: string]: Calendar[] }>({});
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [editLeader, setEditLeader] = useState<Leader|undefined>(undefined);
  const [editCalendarId, setEditCalendarId] = useState<number|undefined>(undefined);
  const [additionalDelegateEmails, setAdditionalDelegateEmails] = useState<(string | null)[]>([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch<ThunkDispatchType>();

  const hasGrant = useMemo(() => (
    !!(user && checkForCalendarGrants(user.oauth_grant_details))
  ), [user]);

  const hasMicrosoftGrant = useMemo(() => (
    !!(user && checkForMicrosoftGrant(user.oauth_grant_details))
  ), [user]);

  useEffect(() => {
    if (hasGrant) {
      setLoading(true);
      const remoteCalRes = dispatch(actions.schedule.fetchRemoteCalendars());
      const calRes = dispatch(actions.schedule.fetchCalendars());
      Promise.all([remoteCalRes, calRes]).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, hasGrant]);

  const delegatorCalendars = useMemo(() => ( 
    calendars.filter(cal => cal.delegatorUser).map(cal => {
      if (cal.delegatorUser) {
        return cal.delegatorUser.email;
      } else return cal.owner_email;
    })
  ), [calendars]);
  
  useEffect(() => {
    if (delegatorCalendars.length) {
      setAdditionalDelegateEmails([...new Set(delegatorCalendars)]);
    }
  }, [delegatorCalendars]);
  

  const getAssociations = useCallback((): AssociationsDict => {
    const newAssociations: AssociationsDict = {};
    calendars.forEach( association => {
      association.leaders.forEach( (leaderId: string | number) => {
        if (newAssociations[leaderId]) {
          newAssociations[leaderId].push(association.id);
        } else {
          newAssociations[leaderId] = [association.id];
        }
      });
    });
    return newAssociations;
  }, [calendars]);

  useEffect(() => {
    const curAssociations = getAssociations();
    setAssociations(curAssociations);
  }, [getAssociations]);

  const handleGetEmailCalendars = async (email: string) => {
    const emailCalendars = await dispatch(actions.schedule.addDelegateCalendarsForEmails([email]));
    return emailCalendars[email];
  };

  const handleSetAdditionalCalendars = (value: { [eml: string]: Calendar[] }) => 
    setAdditionalCalendars(value);

  const handleManageAccountsClick = () => {
    navigate(PAGE_URL.INTEGRATION_SETTINGS);
  };

  const handleOpenAddEditModal = (leaderId: number, calendarId?: number) => {
    setEditLeader(leaders.leaders.find(leader => leader.id === leaderId));
    setEditCalendarId(calendarId);
    setOpenAddEditModal(true);
  };

  const handleCloseAddEditModal = () => {
    setEditLeader(undefined);
    setEditCalendarId(undefined);
    setOpenAddEditModal(false);
  };

  const handleAddCalendar = async (
    calId: number,
    conflicts: boolean,
    analytics: boolean,
    color: string
  ) => {
    const providerId = calendars.find(cal => cal.id === calId)?.provider;
    if (editLeader && providerId) {
      const newAssociation = {
        leader_id: editLeader.id, 
        calId: calId, 
        provider: providerId, 
        associate: true,
        prevent_conflict: conflicts,
        allow_calendar_analytics: analytics
      };
      await dispatch(actions.schedule.updateLeaderCalendars([newAssociation])).finally(() => {
        trackEvent(EVENT_TYPE.SCHEDULING_ASSOCIATE);
      });
      dispatch(
        actions.schedule.updateCalendar({
          id: calId,
          overrideBackgroundColor: color
        })
      );
    }
    handleCloseAddEditModal();
  };

  const handleRemoveCalendar = (leaderId: number, calId: number) => {
    const providerId = calendars.find(cal => cal.id === calId)?.provider;
    if (providerId) {
      const newAssociation = {
        leader_id: leaderId, 
        calId: calId, 
        provider: providerId, 
        associate: false,
        prevent_conflict: false,
        allow_calendar_analytics: false
      };
      dispatch(actions.schedule.updateLeaderCalendars([newAssociation]));
      trackEvent(EVENT_TYPE.SCHEDULING_ASSOCIATE);
    }
  };

  return (
    <>
      <ManageCalendars
        calendars={calendars}
        onManageAccountsClick={handleManageAccountsClick}
        leaders={leaders.leaders}
        leadersLoaded={leaders.loaded}
        openAddEditModal={handleOpenAddEditModal}
        showMissing={hasMicrosoftGrant || !!user?.features.SHOW_DELEGATE_UI}
        removeCalendar={handleRemoveCalendar}
        handleSetAdditionalCalendars={handleSetAdditionalCalendars}
        additionalCalendars={additionalCalendars}
        onGetEmailCalendars={handleGetEmailCalendars}
        hasGrant={hasGrant}
        additionalDelegateEmails={additionalDelegateEmails}
        loading={loading}
      />
      {openAddEditModal && editLeader && (
        <AddEditCalendarModal
          open={openAddEditModal}
          calendars={
            editCalendarId ? calendars.filter(calendar => calendar.id === editCalendarId)
              : associations[editLeader.id] ? 
                calendars.filter(calendar => !associations[editLeader.id].includes(calendar.id))
                : calendars
          }
          leader={editLeader}
          onClose={handleCloseAddEditModal}
          calendarId={editCalendarId}
          onAddEditCalendar={handleAddCalendar}
        />
      )}
    </>
  );
};

export default ManageCalendarsContainer;