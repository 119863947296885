import { ReactElement, useState } from "react";
import { Calendar} from "../../../store";
import { Alert, Box, Divider, FormControl, FormLabel, Skeleton, Typography, styled } from "@mui/material";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import colors from "../../../colors";
import { Controller, useForm } from "react-hook-form";
import { CabModal } from "@CabComponents/CabModal";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { EVENT_TYPE, emailRegex } from "../../../constants";
import { trackEvent } from "../../../utils/appAnalyticsUtils";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import CabSpinner from "@CabComponents/CabSpinner";
import { IoCheckmarkCircleOutline } from "react-icons/io5";


export interface MissingCalendarsProps {
  handleSetAdditionalCalendars: (value: { [eml: string]: Calendar[] }) => void
  additionalCalendars: { [eml: string]: Calendar[] }
  onGetEmailCalendars: (email: string) => Promise<Calendar[]>
  additionalDelegateEmails: (string | null)[];
  loading: boolean;
}

export const MissingCalendars = ({
  handleSetAdditionalCalendars, additionalCalendars, onGetEmailCalendars, additionalDelegateEmails, loading
}: MissingCalendarsProps): ReactElement => {

  const [showEmailModal, setShowEmailModal] = useState(false);

  const additionalEmails = [...new Set([...Object.keys(additionalCalendars), ...additionalDelegateEmails])];

  const {
    control: emailControl, formState: { errors: emailErrors, isSubmitting }, setError, handleSubmit: handleEmailSubmit,
    getValues, reset
  } = useForm<{ email: string }>({
    defaultValues: { email: '' },
  });

  const handleGetEmailCalendars = async (email: string) => {
    const calendars = await onGetEmailCalendars(email);
    if (calendars.length === 0) {
      setError('email', { message: 'No calendars found. Please try another email address.' });
    } else {
      trackEvent(EVENT_TYPE.ONBOARD_MS_DELEGATE);
      handleSetAdditionalCalendars({ ...additionalCalendars, [email]: calendars });
      setShowEmailModal(false);
      reset();
    }
  };

  return (
    <>
      <Container>
        <ColoredBox borderBottom={Object.keys(additionalCalendars).length ? '1px solid' : undefined}>
          <Typography fontWeight={700}>Missing some of your team's calendars?</Typography>
          <Typography color={colors.black800}>
            If you have delegate access to a calendar, it may not show up in the list. It can be added by email below.
            <br /><br />Note: This will only find calendars for which you are a delegate
          </Typography>
          <Box>
            <CabButton
              onClick={() => setShowEmailModal(true)}
              buttonType="text"
              color="primary"
              sx={{marginLeft: -1, fontWeight: 700}}
            >
              Add executive email address
            </CabButton>
          </Box>
        </ColoredBox>
        {!loading ? (
          <>
            {additionalEmails.length > 0 && (
              <Box width='100%' padding={2}>
                {additionalEmails.map((emailAddress, idx) => (
                  <Box key={emailAddress}>
                    {idx > 0 && (
                      <Divider />
                    )}
                    <Box paddingBottom={1.5} alignItems='center' 
                      justifyContent='space-between' display='flex' gap={1}>
                      <Typography variant="body1" sx={{overflowWrap: 'anywhere'}}>
                        {emailAddress}
                      </Typography>
                      <CabToggleChip
                        label='Calendar Found'
                        icon={<CabIcon Icon={IoCheckmarkCircleOutline} sx={{ fontSize: 20, paddingBottom: '2px' }} />}
                        sx={{ 
                          backgroundColor: colors.lightBackgroundGreen, borderColor: colors.lightBackgroundGreen, 
                          height: 30, fontWeight: 500, color: colors.greenPrimary, 
                          '& .MuiChip-icon':{ color: colors.greenPrimary}
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </>
        ) : (
          <Box width='100%' paddingLeft={1} paddingRight={1}>
            <Skeleton height={50} width={'100%'} />
          </Box>
        )}
      </Container>

      <CabModal
        open={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        title="Fetch calendars from email"
        closeOnBackdropClick={!isSubmitting}
        closeIcon={!isSubmitting}
        actionButtons={<>
          <CabButton
            onClick={() => setShowEmailModal(false)}
            buttonType="tertiary"
            disabled={isSubmitting}
          >Cancel</CabButton>
          <CabButton
            onClick={() => handleEmailSubmit(({ email }) => handleGetEmailCalendars(email))()}
            disabled={isSubmitting}
          >Fetch Calendars</CabButton>
        </>}
      >
        {isSubmitting ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box margin={2}>
              <CabSpinner scale={1.0} />
            </Box>
            <Typography color={colors.black600}>Fetching calendars from</Typography>
            <Typography color={colors.black600} fontWeight={700}>{getValues('email')}</Typography>
          </Box>
        ) : (
          <FormControl fullWidth>
            <FormLabel>Executive's email</FormLabel>
            <Controller
              name="email"
              control={emailControl}
              rules={{ required: true, pattern: emailRegex }}
              render={({ field: { ref, ...field } }) => (
                <CabTextInput {...field} />
              )}
            />
            {emailErrors.email && (
              <Alert severity="error" sx={{ marginTop: 1 }}>
                {emailErrors.email.message
                || (emailErrors?.email?.type === 'required' ? 'Email is required'
                  : emailErrors.email?.type === 'pattern' ? 'Please enter a valid email address'
                    : null)}
              </Alert>
            )}
          </FormControl>
        )}
      </CabModal>
    </>
  );
};

const Container = styled(Box, {label: "Container"})(() => ({
  maxWidth: 700,
  minWidth: 350,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  border: '1px solid',
  borderColor: colors.black200,
  borderRadius: 6,
  marginRight: 8
}));

const ColoredBox = styled(Box, {label: "ColoredBox"})(() => ({
  maxWidth: 700,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  backgroundColor: colors.lightBackgroundGreen,
  padding: 16,
  borderRadius: 6,
  borderColor: colors.black200
}));